import styled from "styled-components";

interface StatusIconProps {
    statusColor: string;
  }
  export const StatusIcon = styled.div<StatusIconProps>`
    background: ${({ statusColor }) => statusColor};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  `;
  
  
export const InvoiceIdTag = styled.div`
background: #757575;
height: 24px;
min-width: 40px;
padding: 0 4px;
display: flex;
align-items: center;
justify-content: center;
font-family: Roboto;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: center;

color: #ffffff;
border-radius: 16px;
`;

interface BodyTRprops {
    isEven?: boolean;
    active?: boolean;
  }
  
  export const BodyTR = styled.tr<BodyTRprops>`
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: ${({ isEven }) => (isEven ? "#ffffff" : "#F5F5F5")};
    cursor: pointer;
  
    td {
      //border: solid 1px #000;
      //border-style: none none;
      padding-left: 10px;
      padding-right: 10px;
      //background-color: cyan;
      text-align: left;
      font: normal normal 500 14px/19px Roboto;
      letter-spacing: 0px;
      color: #212121;
      height: 60px;
  
      :first-child {
        //border-left-style: solid;
        /*border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;*/
        /* padding-left: 24px; */
        border-left: 8px ${(props) => (props.active ? "#18AA36" : "transparent")}
          solid;
  
        :hover {
          border-left: 8px ${(props) => (props.active ? "#18AA36" : "#f7fcf8")}
            solid;
        }
      }
      :last-child {
        padding-right: 24px;
  
        //border-right-style: solid;
        /*border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;*/
      }
    }
    :hover {
      background: #f7fcf8 0% 0% no-repeat padding-box;
      td {
        :first-child {
          //border-left-style: solid;
          /*border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;*/
          /* padding-left: 24px; */
          border-left: 8px #18aa36 solid;
        }
      }
    }
  `;