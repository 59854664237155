import styled, { css } from "styled-components";

export const Modal = styled.div`
  //padding-top: 25px;
  //padding-left: 50px;
  //padding-right: 20000px;
  //padding-bottom: 25px;
  min-height: 100vh;
  background-color: #f5f5f5;
  width: 100vw;
  //margin-left: 50vw;
  height: 100%;
  flex: 1;

  .item_width-100 {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
  .big-loading-roll {
    width: 128px;
    height: 128px;
  }
`;

export const ModalHeader = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 64px;
  border-bottom: 1px solid #dddddd;
`;
export const HeaderTitle = styled.h1`
  font: normal normal 500 24px/32px Roboto;
  letter-spacing: 0px;
  color: #212121;
`;
export const HeaderButton = styled.button`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 8px;

  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0px;
  border: 0;
  color: #212121;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const HeaderButtonSave = styled.button`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 8px;

  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0px;
  border: 0;
  color: #ffffff;
  background: #008940;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const ModalContainer = styled.div`
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  //flex: 1;
  height: calc(100vh - 80px);
  padding: 16px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background: transparent;

    // display:none;

    width: 0px;
  }

  &::-webkit-scrollbar-track {
    /*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
    // margin-top: 54px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);

    background-clip: padding-box;
    max-height: 5px;
    transition: all 1s;
  }
`;
export const BodyMenu = styled.div`
  /*padding-left: 30px;
	padding-right: 30px;
	max-height: calc(100vh - 80px);*/
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;

  padding-top: 30px;
  padding-bottom: 30px;

  //border-right: 1px solid #f0f0f0;
`;

interface MenuItemProps {
  backgroundColor?: string;
}
export const MenuItem = styled.div<MenuItemProps>`
  ${({ backgroundColor }) => css`
    background: ${backgroundColor || "#ffffff"} 0% 0% no-repeat padding-box;
    border-radius: 24px;
    padding: 8px 16px;
    margin-bottom: 15px;
    position: relative;

    input {
      outline: none;
    }

    .menu_input_container {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 19px;
      height: 38px;
      display: flex;
      flex-direction: row;
      //padding: 12px;
      padding-right: 0;
      align-items: center;
      padding-left: 12px;

      img {
        width: 20px;
        height: 20px;
      }

      input {
        flex: 1;
        //::after//margin-top: -12px;
        //margin-bottom: -12px;
        margin: 0;
        background: transparent;
        height: 38px;
        border: 0;
      }

      .menu_search_button {
        background: #28a745 0% 0% no-repeat padding-box;
        border-radius: 19px;
        height: 38px;
        text-align: center;
        font: normal normal 500 13px/18px Roboto;
        letter-spacing: 0px;
        color: #ffffff;
        //margin-top: -12px;
        //margin-bottom: -12px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        border: 0;
      }
    }

    .float_right {
      float: right;
    }

    .table_row {
    }

    table {
      width: 100%;
      border-spacing: 16px;
    }

    .menu_padding_b-24 {
      padding-bottom: 24px;
    }

    .menu_padding_b-16 {
      padding-bottom: 16px;
    }

    .menu_padding_t-24 {
      padding-top: 24px;
    }

    .menu_padding_t-16 {
      padding-top: 16px;
    }

    .menu_border_b-gray {
      border-bottom: 1px solid #ebebeb;
    }

    .menu_margin_b-8 {
      margin-bottom: 8px;
    }

    .menu_margin_b-14 {
      margin-bottom: 14px;
    }

    .menu_margin_b-16 {
      margin-bottom: 16px;
    }

    .menu_margin_b-32 {
      margin-bottom: 32px;
    }

    .menu_padding_b-16 {
      padding-bottom: 16px;
    }
    .menu_margin_b-2 {
      margin-bottom: 2px;
    }

    .menu_margin_b-20 {
      margin-bottom: 20px;
    }

    .menu_circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #28a745 0% 0% no-repeat padding-box;
      border-radius: 12px;
      width: 24px;
      height: 24px;
    }

    p {
      margin: 0;
    }

    .menu_item_header {
      border-bottom: 1px solid ${"#DDDDDD"};
    }

    .menu_item_title {
      text-align: left;
      font: normal normal bold 16px/32px Roboto;
      letter-spacing: 0px;
      color: #212121;
    }
    .menu_item_label {
      text-align: left;
      font: normal normal normal 11px/15px Roboto;
      letter-spacing: 0px;
      color: #676767;
      text-transform: uppercase;
    }
    .menu_item_status_info {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #212121;
      text-align: left;
    }

    .menu_item_info {
      text-align: left;
      font: normal normal 500 15px/20px Roboto;
      letter-spacing: 0px;
      color: #222222;
    }

    .menu_item_info_bold {
      text-align: left;
      font: normal normal 700 15px/20px Roboto;
      letter-spacing: 0px;
      color: #222222;
    }
    .menu_item_info_regular {
      text-align: left;
      font: normal normal 400 15px/20px Roboto;
      letter-spacing: 0px;
      color: #222222;
    }

    .menu_item_ellipsis {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .menu_item_invoice_value {
      font: normal normal bold 15px/20px Roboto;
      letter-spacing: 0px;
      color: #222222;
    }

    .menu_item_status {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #676767 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font: normal normal 500 10px Roboto;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-transform: uppercase;
      padding-left: 12px;
      padding-right: 12px;
    }

    .menu_item_heavy {
      text-align: right;
      font: normal normal bold 18px/24px Roboto;
      letter-spacing: 0px;
      color: #222222;
    }

    .menu_bottom_fixed_br {
      position: absolute;
      background: #28a745 0% 0% no-repeat padding-box;
      border-radius: 24px 0px;
      bottom: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      display: flex;
    }

    .menu_top_fixed_tr_checked {
      position: absolute;
      background: #28a745 0% 0% no-repeat padding-box;
      border-radius: 0px 24px;
      top: 0;
      right: 0;

      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
    }

    .menu_top_fixed_tr {
      position: absolute;
      background: #fec12d 0% 0% no-repeat padding-box;
      border-radius: 0px 24px;
      top: 0;
      right: 0;

      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
    }

    .menu_detalhes_status {
      padding: 0 8px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #212121;
    }
    .menu_item_grid {
      display: grid;
    }
  `}
`;

interface StatusIconProps {
  statusColor: string;
}
export const StatusIcon = styled.div<StatusIconProps>`
  background: ${({ statusColor }) => statusColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

interface GridContainerProps {
  gridTemplateColumns?: string;
}
export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${({ gridTemplateColumns }) =>
    gridTemplateColumns || "1fr 1fr"};
`;


export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 32px;
  gap: 80px;
  .search_item_container {
    background: #ffffff;
  }

  .not_found_img {
    width: 172px;
    height: 128px;
  }
  .not_found_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #212121;

    margin: 0;
  }
`;