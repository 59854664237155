/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from "react";

import ClearIcon from "../../assets/images/clearIcon.svg";
import ArrowDark from "../../assets/images/arrow_dropdown-dark.svg";
import SearchGray from "../../assets/images/search-gray.svg";
import Loading from "../../assets/images/loading-roll.svg";

import styled from "styled-components";
import debounce from "lodash.debounce";
import { Dropdown } from "react-bootstrap";
import { useEffect } from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import * as S from "./styles";

interface SearchSelectorProps {
  active?: boolean;
  pressed?: boolean;
}
export const SearchSelector = styled.button<SearchSelectorProps>`
  //height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 10px;

  background: ${(props) => (props.active ? "#18AA36" : "#F0F0F0")} 0% 0%
    no-repeat padding-box;
  border-radius: 20px;

  text-align: center;
  font: normal normal 500 12px/16px Roboto;
  letter-spacing: 0px;
  color: ${(props) => (props.active ? "#FFFFFF" : "#676767")};
  //text-transform: uppercase;
  cursor: pointer;
  border: ${(props) =>
    props.pressed ? "1px solid #EBEBEB" : "1px solid transparent"};

  box-shadow: ${(props) => (props.pressed ? "inset 0px 3px 6px #00000029" : 0)};
`;

export function InputContainerV3({
  isError,
  inputName,
  setInputField,
  fieldLabel,
  fieldLabelError,
  placeholder,
  disabled,
  type,
  name,
  changeError,
}: {
  isError?: boolean;
  inputName: string;
  setInputField(s: string): void;
  fieldLabel: string;
  fieldLabelError?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  changeError?(s: string): void;
  name?: string;
}) {
  const inputRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const [focusInput, setFocusInput] = useState(false);
  const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (name && changeError && isError) {
      changeError(name);
    }
  }, [inputName]);

  useEffect(() => {
    setErrorActive(isError);
    if (isError && containerRef && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isError]);
  return (
    <S.InputCover ref={containerRef}>
      {!errorActive ? (
        <S.ModalLabel className="mb-8">{fieldLabel}</S.ModalLabel>
      ) : (
        <S.ModalLabelError className="mb-8">
          {fieldLabelError ? fieldLabelError : fieldLabel}
        </S.ModalLabelError>
      )}

      <S.ModalInputContainer
        shadowColor={
          errorActive && focusInput
            ? "rgba(232,69,62,0.75)"
            : focusInput
            ? "rgba(40,167,69,0.75)"
            : "transparent"
        }
        borderColor={
          errorActive
            ? "#E8453E"
            : focusInput || inputName.length > 0
            ? "#28A745"
            : "#9f9f9f"
        }
      >
        <input
          ref={inputRef}
          className="modal_input"
          type={type ? type : "text"}
          value={inputName}
          disabled={disabled}
          onChange={(e) => {
            setInputField(e.target.value);
            setErrorActive(false);
          }}
          onScroll={(e) => {
            e.preventDefault();
            if (type === "number")
              if (inputRef && inputRef.current) {
                inputRef.current.blur();
              }
          }}
          onWheel={(e) => {
            e.preventDefault();
            //e.
            if (type === "number")
              if (inputRef && inputRef.current) {
                inputRef.current.blur();
              }
          }}
          placeholder={placeholder}
          onFocus={() => {
            setFocusInput(true);
          }}
          onBlur={() => {
            setFocusInput(false);
          }}
        />
      </S.ModalInputContainer>
    </S.InputCover>
  );
}

export function InputMaskContainer({
  isError,
  inputName,
  setInputField,
  fieldLabel,
  fieldLabelError,
  placeholder,
  disabled,
  changeError,
  name,
}: {
  isError?: boolean;
  inputName: string;
  setInputField(s: string): void;
  fieldLabel: string;
  fieldLabelError?: string;
  placeholder?: string;
  disabled?: boolean;
  changeError?(s: string): void;
  name?: string;
}) {
  const inputRef = useRef<any>(null);

  const [focusInput, setFocusInput] = useState(false);
  const [errorActive, setErrorActive] = useState<boolean | undefined>(false);
  useEffect(() => {
    if (name && changeError && isError) {
      changeError(name);
    }
  }, [inputName]);
  useEffect(() => {
    setErrorActive(isError);
  }, [isError]);

  function maskDocument(preValue: string): string {
    let value = preValue;
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  return (
    <S.InputCover
      onClick={(e) => {
        e.preventDefault();
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className="mt-24"
    >
      {!errorActive ? (
        <S.ModalLabel className="mb-8">{fieldLabel}</S.ModalLabel>
      ) : (
        <S.ModalLabelError className="mb-8">
          {fieldLabelError ? fieldLabelError : fieldLabel}
        </S.ModalLabelError>
      )}
      <S.ModalInputContainer
        shadowColor={
          errorActive && focusInput
            ? "rgba(232,69,62,0.75)"
            : focusInput
            ? "rgba(40,167,69,0.75)"
            : "transparent"
        }
        borderColor={
          errorActive
            ? "#E8453E"
            : focusInput || inputName.length > 0
            ? "#28A745"
            : "#9f9f9f"
        }
      >
        <input
          disabled={disabled}
          ref={inputRef}
          className="modal_input"
          type="text"
          value={inputName}
          onChange={(e) => {
            setInputField(maskDocument(e.target.value));
            setErrorActive(false);
          }}
          placeholder={placeholder}
          onFocus={() => {
            setFocusInput(true);
          }}
          onBlur={() => {
            setFocusInput(false);
          }}
        />
        {inputName.length > 0 && !disabled && (
          <div
            style={{ opacity: focusInput ? 1 : 0 }}
            className="modal_input_icon_container"
          >
            <img
              className="modal_input_icon"
              src={ClearIcon}
              alt="x"
              onClick={(e) => {
                e.preventDefault();
                setInputField("");
              }}
            />
          </div>
        )}
      </S.ModalInputContainer>
    </S.InputCover>
  );
}

export function SelectContainerV3({
  isError,
  inputName,
  setInputField,
  fieldLabel,
  fieldLabelError,
  placeholder,
  options,
  disabled,
  name,
  changeError,
}: {
  isError?: boolean;
  inputName: string;
  setInputField(s: string): void;
  fieldLabel: string;
  fieldLabelError?: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  changeError?(s: string): void;
  name?: string;
}) {
  const inputRef = useRef<any>(null);

  const [focusInput, setFocusInput] = useState(false);

  const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

  const [shownInput, setShownInput] = useState("");

  useEffect(() => {
    if (name && changeError && isError) {
      changeError(name);
    }
  }, [inputName]);

  useEffect(() => {
    const f = options.find((o) => o.value === inputName);

    if (f) {
      setShownInput(f.label);
    } else {
      setShownInput("");
    }
  }, [inputName, options]);

  useEffect(() => {
    setErrorActive(isError);
  }, [isError]);

  const dropdownRef = useRef<any>(null);

  useOutsideAlert(dropdownRef, handleOutside);

  function handleOutside() {
    setFocusInput(false);
  }

  return (
    <S.InputCover
      onClick={(e) => {
        e.preventDefault();
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className="mt-24"
    >
      {!errorActive ? (
        <S.ModalLabel className="mb-8">{fieldLabel}</S.ModalLabel>
      ) : (
        <S.ModalLabelError className="mb-8">
          {fieldLabelError ? fieldLabelError : fieldLabel}
        </S.ModalLabelError>
      )}
      <S.ModalInputContainer
        //style={{ paddingRight: 12 }}
        shadowColor={
          errorActive && focusInput
            ? "rgba(232,69,62,0.75)"
            : focusInput
            ? "rgba(40,167,69,0.75)"
            : "transparent"
        }
        borderColor={
          errorActive
            ? "#E8453E"
            : focusInput || inputName.length > 0
            ? "#28A745"
            : "#9f9f9f"
        }
      >
        {
          <Dropdown ref={dropdownRef}>
            <Dropdown.Toggle className="dropdown-none">
              <SearchSelector
                onClick={(e) => {
                  setFocusInput(true);
                }}
                className="modal_input"
              >
                {shownInput}
              </SearchSelector>
              <img alt="v" src={ArrowDark} />
            </Dropdown.Toggle>
            <DropdownMenu>
              {options.map((o) => {
                if (o.value === "") return null;
                return (
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setInputField(o.value);
                      setFocusInput(false);
                    }}
                  >
                    {o.label}
                  </Dropdown.Item>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        }

        {/*<select
					//style={{paddingRight:16}}
					disabled={disabled}
					ref={inputRef}
					className='modal_input'
					value={inputName}
					onChange={(e) => {
						setInputField(e.target.value);
						setErrorActive(false);
					}}
					placeholder={placeholder}
					onFocus={(e) => {
						setFocusInput(true);
						//e.target.size=options.length;
					}}
					onBlur={(e) => {
						setFocusInput(false);
						//e.target.size=0;
					}}>
					{options.map((o) => (
						<option value={o.value}>{o.label}</option>
					))}
				</select>*/}
      </S.ModalInputContainer>
    </S.InputCover>
  );
}

export function InputContainerPercentage({
  isError,
  inputName,
  setInputField,
  fieldLabel,
  fieldLabelError,
  placeholder,
  disabled,
  type,
  name,
  changeError,
}: {
  isError?: boolean;
  inputName: string;
  setInputField(s: string): void;
  fieldLabel: string;
  fieldLabelError?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  changeError?(s: string): void;
  name?: string;
}) {
  const inputRef = useRef<any>(null);

  const [focusInput, setFocusInput] = useState(false);
  const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (name && changeError && isError) {
      changeError(name);
    }
  }, [inputName]);
  useEffect(() => {
    setErrorActive(isError);
  }, [isError]);
  return (
    <S.InputCover
      onClick={(e) => {
        e.preventDefault();
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className="mt-24"
    >
      {!errorActive ? (
        <S.ModalLabel className="mb-8">{fieldLabel}</S.ModalLabel>
      ) : (
        <S.ModalLabelError className="mb-8">
          {fieldLabelError ? fieldLabelError : fieldLabel}
        </S.ModalLabelError>
      )}
      <S.ModalInputContainer
        style={{ overflow: "hidden" }}
        shadowColor={
          errorActive && focusInput
            ? "rgba(232,69,62,0.75)"
            : focusInput
            ? "rgba(40,167,69,0.75)"
            : "transparent"
        }
        borderColor={
          errorActive
            ? "#E8453E"
            : focusInput || inputName.length > 0
            ? "#28A745"
            : "#9f9f9f"
        }
      >
        <input
          disabled={disabled}
          ref={inputRef}
          className="modal_input"
          type={type ? type : "text"}
          value={inputName}
          onScroll={(e) => {
            e.preventDefault();
            if (type === "number")
              if (inputRef && inputRef.current) {
                inputRef.current.blur();
              }
          }}
          onWheel={(e) => {
            e.preventDefault();
            //e.
            if (type === "number")
              if (inputRef && inputRef.current) {
                inputRef.current.blur();
              }
          }}
          onChange={(e) => {
            setInputField(e.target.value);
            setErrorActive(false);
          }}
          placeholder={placeholder}
          onFocus={() => {
            setFocusInput(true);
          }}
          onBlur={() => {
            setFocusInput(false);
          }}
        />
        <div className="percentage_container">%</div>
      </S.ModalInputContainer>
    </S.InputCover>
  );
}

export function DebouncerInput({
  setSearchItem,
  placeholder,
  customClassName,
  isButtonActive,
  searchFunction,
  isLoading,
  customSearchText,
}: {
  setSearchItem(s: string): void;
  placeholder?: string;
  customClassName?: string;
  isButtonActive?: boolean;
  searchFunction?(): void;
  isLoading?: boolean;
  customSearchText?: string;
}) {
  const [searchInput, setSearchInput] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSave = useCallback(
  //   debounce((nextValue) => setSearchItem(nextValue), 1000),
  //   []
  // );

  function handleChange(s: string) {
    setSearchInput(s);
    setSearchItem(s);
    // debouncedSave(s);
  }

  return (
    <S.HeaderSearchContainer
      onSubmit={(e) => {
        e.preventDefault();
        //console.log(searchActive);
        setSearchItem(searchInput);
      }}
      active={true}
      className={customClassName}
    >
      <button type="submit">
        <img src={SearchGray} alt="src" style={{ width: 24, height: 24 }} />
      </button>

      <S.HeaderSearchInput
        value={searchInput}
        onChange={(e) => {
          //console.log(e.target.value)
          handleChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (searchFunction) {
            if (e.key === "Enter") {
              e.preventDefault();
              searchFunction();
            }
          }
        }}
        placeholder={placeholder ? placeholder : ""}
      />
      {searchFunction ? (
        <S.HeaderSearchSubmitButton
          onClick={() => {
            searchFunction();
          }}
          active={isButtonActive}
        >
          {isLoading ? (
            <img
              style={{ width: 16, height: 16 }}
              src={Loading}
              alt="loading"
            />
          ) : (
            customSearchText || "Buscar"
          )}
        </S.HeaderSearchSubmitButton>
      ) : null}
    </S.HeaderSearchContainer>
  );
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
