import api from "../../services/api";
import fileDownload from "js-file-download";
import { PaymentRequestDetail } from "./ConciliadorPaymentModal/Models";

export async function exportReportSearchRequest(
  startDate: string,
  endDate: string,
  merchantId?: number | string
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/report/pdf?start=${startDate}&end=${endDate}&merchant_id=${merchantId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
      }
    );

    const { data } = response;

    if (data) {
      fileDownload(response.data, `relatorios${startDate}_${endDate}.${"pdf"}`);
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getPaymentInvoiceRequest(invoiceId: string | number) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/payment-request/invoice?invoice=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getPaymentInvoiceCancelationRequest(
  invoiceId: string | number
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/cancellation-request/invoice?invoice=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postCreateRequestProcess(
  invoiceId: number | string
): Promise<PaymentRequestDetail> {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/payment-request`,
      { invoice: invoiceId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data.payment_request;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postPaymentRequestProcess(
  processId: number,
  postObj: any
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/payment-request/${processId}/process`,
      postObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getPaymentRequestList(queryParam?: string) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/invoice-adjustment${queryParam || ""}`,
      // `/admin/api/payment-request${queryParam||''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getCancellationRequestList(queryParam?: string) {
  try {
    const token = sessionStorage.getItem("@bill-token");
    const response = await api.get(
      `/admin/api/cancellation-request${queryParam || ""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data } = response;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function postCreateCancellationRequest(
  invoiceId: number | string
): Promise<PaymentRequestDetail> {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/cancellation-request`,
      { invoice: invoiceId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data.cancellation_request;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postPaymentCancellationRequest(
  processId: number,
  postObj: any
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/cancellation-request/${processId}/process`,
      postObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getSingleInvoiceAjustment(
  invoiceId: number | string,
  type: number | string
) {
  const token = sessionStorage.getItem("@bill-token");
  try {
    const response = await api.get(
      `/admin/api/invoice-adjustment/invoice?invoice=${invoiceId}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function postCreateSingleInvoiceAjustment(
  invoiceId: number | string,
  type: number | string
) {
  const token = sessionStorage.getItem("@bill-token");
  try {
    const response = await api.post(
      `/admin/api/invoice-adjustment`,
      {
        invoice: invoiceId,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data.invoice_adjustment;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function patchProcressSingleInvoiceAjustment(
  id: number,
  patchObj: {
    ref_date: string;
    payment_method?: number | string;
    total?: number;
    reason: string;
    type: number;
  }
) {
  const token = sessionStorage.getItem("@bill-token");
  try {
    const response = await api.patch(
      `/admin/api/invoice-adjustment/${id}`,
      patchObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const invoiceAjustmenTypes = [
  {
    value: 1,
    label: "Baixa manual",
  },
  {
    value: 2,
    label: "Reativação",
  },
  {
    value: 3,
    label: "Exclusão",
  },
];

