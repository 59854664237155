import styled, { css } from "styled-components";

export const InputCover = styled.div`
  width: 100%;
  .mb-8 {
    margin-bottom: 8px;
  }
`;
export const ModalLabel = styled.p`
  margin: 0;
  text-align: left;
  font: normal normal 500 11px/15px Roboto;
  letter-spacing: 0px;
  color: #4b4b4b;
  text-transform: uppercase;
`;
export const ModalLabelError = styled.p`
  margin: 0;
  text-align: left;
  font: normal normal 500 11px/15px Roboto;
  letter-spacing: 0px;
  color: #e8453e;
  text-transform: uppercase;
`;

interface ModalInputContainerProps {
  shadowColor?: string;
  borderColor?: string;
  disabled?: boolean;
}
export const ModalInputContainer = styled.div<ModalInputContainerProps>`
  background: ${(props) => (props.disabled ? "#F0F0F0" : "#ffffff")} 0% 0%
    no-repeat padding-box;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#9f9f9f")};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 50px;
  box-shadow: 0px 0px 10px 1px
    ${(props) => (props.shadowColor ? props.shadowColor : "transparent")};
  -webkit-box-shadow: 0px 0px 10px 1px
    ${(props) => (props.shadowColor ? props.shadowColor : "transparent")};
  -moz-box-shadow: 0px 0px 10px 1px
    ${(props) => (props.shadowColor ? props.shadowColor : "transparent")};

  .dropdown {
    flex: 1;
    .dropdown-none {
      //
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 8px !important;
    }

    .dropdown-item {
      height: 27px;
    }
  }
  //overflow: hidden;
  .modal_input_icon_container {
    height: 100%;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_input_icon {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    align-self: center;
    cursor: pointer;
  }

  .modal_select_input {
    border: transparent;
    border-radius: 16px;
    flex: 1;
    overflow: hidden;
    padding-left: 16px;
    text-align: left;
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;

    background-color: transparent;

    ::-webkit-input-placeholder {
      /* Edge */
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }

    ::placeholder {
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }
  }

  .modal_input {
    border: transparent;
    border-radius: 16px;
    flex: 1;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;

    background-color: transparent;

    ::-webkit-input-placeholder {
      /* Edge */
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }

    ::placeholder {
      text-align: left;
      font: normal normal 300 14px/19px Roboto;
      letter-spacing: 0px;
      color: #9f9f9f;
    }
  }

  .percentage_container {
    background: #09aa36 0% 0% no-repeat padding-box;
    border: 1px solid #09aa36;
    // border-radius: 0px 16px 16px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
    height: 50px;
    font: normal normal 500 18px/24px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

interface HeaderSearchContainerProps {
  active?: boolean;
}

export const HeaderSearchContainer = styled.form<HeaderSearchContainerProps>`
  height: 40px;
  display: flex;
  flex-direction: row;

  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: 0;
  opacity: 1;
  align-items: center;
  min-width: 230px;

  margin-right: 24px;

  input {
    //display: ${(props) => !props.active && "none"};
    transition: all 1s;
    width: ${(props) => (!props.active ? 0 : "230px")};
    // margin-left: ${(props) => (!props.active ? 0 : "16px")};
    padding: ${(props) => (!props.active ? 0 : "11px")};
  }

  button {
    cursor: pointer;
    padding: 11px;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
  }

  svg {
    color: #09aa36;
    width: 16px;
    height: 16px;
  }
`;

export const HeaderSearchSubmitButton = styled.button<HeaderSearchContainerProps>`
  ${({ active }) => css`
    outline: none;
    border: none;
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${active ? "#008940" : "#E0E0E0"} !important;
    color: ${active ? "#ffffff" : "#757575"} !important;
    border-radius: 24px;
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-right: 8px;

  `}
`;

export const HeaderSearchInput = styled.input`
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #9f9f9f;
  opacity: 1;
  flex: 1;
  border: 0;
  background-color: transparent;
  //padding:11px;
`;
