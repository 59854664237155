import { useState } from "react";
import { Payment } from "../../../../models/model";
import { GlobalRow } from "../../../../utils/GlobalRow";
import {
  invoiceTransactionStatusesData,
  statusesData,
} from "../../../../utils/statusesList";
import * as S from "./styles";
import moment from "moment";
import { StringToCurrencyNoSifra } from "../../../../utils/StringToCurrency";
import { Modal } from "react-bootstrap";
import { ModalRelatorioScreen } from "../RelatorioModal";
import { InvoiceAjustmentProps } from "../../Models";

export function PaymentTR({
  payment,
  selectedPayments,
  index,
  isAdm,
}: {
  selectedPayments: string[];
  payment: InvoiceAjustmentProps;
  index: number;
  isAdm: boolean;
}) {
  function isSelected() {
    return selectedPayments.includes(`${payment.id}`);
  }

  //console.log(relatorio);
  const [modalVisible, setModalVisible] = useState(false);

  function getStatus() {
    const find = invoiceTransactionStatusesData.find(
      (f) => f.value === payment.status
    );
    if (find) {
      return (
        <GlobalRow justifyContent="flex-start" gap="8px">
          {find.iconColor ? (
            <S.StatusIcon statusColor={find.iconColor} />
          ) : null}{" "}
          {find.label}
        </GlobalRow>
      );
    }
    return payment.status;
  }

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }
  function StringToLocalDateNotHour(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY");
  }

  function paymentType() {
    if (payment.type === 1) {
      return "Baixa manual";
    }
    if (payment.type === 2) {
      return "Reativação";
    }
    if (payment.type === 3) {
      return "Exclusão";
    }

    return "-";
  }

  return (
    <>
      <S.BodyTR
        isEven={index % 2 === 0}
        active={isSelected()}
        onClick={(e) => {
          e.preventDefault();

          //   handleSelectedInvoices(payment.id);
        }}
      >
        <td>{payment?.invoice?.code || "-"}</td>
        <td>{paymentType()}</td>
        <td className="td_item_ellipsis td_max_width-128">
          {payment?.invoice?.content.customer || "-"}
        </td>
        <td className="td_item_ellipsis td_max_width-128">
          {payment?.invoice?.content.extras?.description || "-"}
        </td>
        <td style={{ textAlign: "right" }}>
          {StringToCurrencyNoSifra(`${payment?.invoice?.total}`)}
        </td>
        <td>
          {StringToLocalDateNotHour(payment?.invoice?.content.billingPeriod)}
        </td>
        <td>
          {StringToLocalDateNotHour(payment?.invoice?.content.paymentDueDate)}
        </td>
        <td>{getStatus()}</td>
        <td>
          {payment?.refDate ? StringToLocalDateNotHour(payment?.refDate) : "-"}
        </td>
        {payment.payment && payment.payment.id ? (
          <td>
            <S.SeeButton
              onClick={() => {
                setModalVisible(true);
              }}
            >
              Ver
            </S.SeeButton>
          </td>
        ) : null}

        {/* <td>{payment.merchant ? payment.merchant.name : "-"}</td>
            <td>
              {payment.transactions[0] ? payment.transactions[0].name : "-"}
            </td>
            <td>{ref || "-"}</td>
            <td>{StringToLocalDate(payment.created)}</td>
            <td>{payment.device?.name || "-"}</td>
            <td>{getStatus()}</td>
            <td style={{ textAlign: "right" }}>
              {StringToCurrencyNoSifra(payment.total)}
            </td> */}
      </S.BodyTR>

      {modalVisible && payment.payment && payment.payment.id ? (
        <Modal
          show={modalVisible}
          onHide={() => {
            setModalVisible(false);
          }}
        >
          <Modal.Body>
            {
              <ModalRelatorioScreen
                setModalVisible={setModalVisible}
                payment={payment.payment}
              />
            }
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
