/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
//import MenuContainer from '../../components/Menu';

import * as S from "./style";
import {
  UilCalendarAlt,
  UilSearch,
  UilFilterSlash,
  UilDownloadAlt,
} from "@iconscout/react-unicons";
import { connect } from "react-redux";
import { InitialStateProps } from "../../store";
import { Dropdown, Modal } from "react-bootstrap";
//import { Modal } from 'react-bootstrap';

//import 'bootstrap/dist/css/bootstrap.min.css';

import "./modal.css";
import { ModalRelatorioScreen } from "./RelatorioModal";
import moment from "moment";
import { DateRange } from "react-date-range";
import { pt } from "date-fns/locale";
import { Empresa, Payment, Relatorio } from "../../models/model";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { colors } from "../../globalStyles/colors";

import ArrowDownWhite from "../../assets/images/arrow_dropdown-white.svg";
import ArrowDownDark from "../../assets/images/arrow_dropdown-dark.svg";
import ArrowRightWhite from "../../assets/images/arrow_right-white.svg";
import ArrowLeftWhite from "../../assets/images/arrow_left-white.svg";
import SearchGray from "../../assets/images/search-gray.svg";
import Loading from "../../assets/images/loading-roll.svg";
import HeaderUserButton from "../../components/logOut";
import fileDownload from "js-file-download";
import { DebouncerInput } from "../../components/InputV2";
import { CalendarioPersonalizado } from "../../components/CalendarioContainer";
import {
  situacoesStatuses,
  statusesData,
  transactionStatuses,
} from "../../utils/statusesList";
import { HeaderUserButtonV2 } from "../../components/logOutV2";
import { StringToCurrencyNoSifra } from "../../utils/StringToCurrency";
import { GlobalRow } from "../../utils/GlobalRow";
import { exportReportSearchRequest } from "./viewModel";
import { NotFoundComponent } from "../../components/NotFound";
import { useSnackbar } from "notistack";
import { snackbarErrorHandler } from "../../utils/snackbarErrorHandler";
import { CalendarioPersonalizadoV2 } from "../../components/CalendarioContainerV2";
import { GlobalColumn } from "../../utils/GlobalColumn";
import { PaymentTR } from "./components/paymentTR";

interface Data {
  modules: any;
  dispatch: any;
}

const LIMIT = 25;

function Relatorios({ modules, dispatch }: Data) {
  const [filtroDia, setFiltroDia] = useState("hoje");
  const { enqueueSnackbar } = useSnackbar();

  const [searchActive, setSearchActive] = useState(false);
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [empresa, setEmpresa] = useState({
    value: "",
    label: "Todas as empresas",
  });
  const [situacao, setSituacao] = useState({
    value: "",
    label: "Todas as situações",
  });
  const [tipo, setTipo] = useState({ value: "", label: "Todas as formas" });
  const [apelido, setApelido] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);

  const [user, setUser] = useState<any>();

  useEffect(() => {
    const login = localStorage.getItem("@bill-login");
    if (login) {
      const parseLogin = JSON.parse(login);

      setUser(parseLogin);
    }
  }, []);

  function handleSelectedInvoices(inv: number) {
    const resp = selectedInvoices.filter((i) => {
      return i !== inv;
    });

    if (resp) {
      if (resp.length === selectedInvoices.length) {
        setSelectedInvoices([...selectedInvoices, inv]);
      } else {
        setSelectedInvoices(resp);
      }
    } else {
      setSelectedInvoices([inv]);
    }
  }

  function handleSelectedPayments(inv: string) {
    const resp = selectedPayments.filter((i) => {
      return i !== inv;
    });

    if (resp) {
      if (resp.length === selectedPayments.length) {
        setSelectedPayments([...selectedPayments, inv]);
      } else {
        setSelectedPayments(resp);
      }
    } else {
      setSelectedPayments([inv]);
    }
  }

  const estados = [
    { label: "", value: "" },
    { label: "Roraima", value: 0 },
    { label: "Amazonas", value: 1 },
    { label: "Acre", value: 2 },
  ];
  const [cidades, seCidades] = useState([
    { label: "", value: "" },
    { label: "Boa Vista", value: 0 },
    { label: "Bonfim", value: 1 },
  ]);
  const situacoes = situacoesStatuses;

  function ToggleModal(module: any, setShowModal: boolean) {
    return {
      type: "setModalVisible",
      module,
      setShowModal,
    };
  }

  const [rangeHeader, setRangeHeader] = useState([
    {
      startDate: moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").toDate(),
      endDate: moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").toDate(),
      key: "selection",
    },
  ]);
  //console.log(range)

  const headerData = [
    { dia: "hoje" },
    { dia: "ontem" },
    { dia: "este mês" },
    //{ dia: 'personalizado', personalizado: true },
  ];

  function handleRange(dia: string) {
    const p = [
      {
        dia: "hoje",
        f: function () {
          setRangeHeader([
            {
              startDate: moment(
                moment().format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).toDate(),
              endDate: moment(
                moment().format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).toDate(),
              key: "selection",
            },
          ]);
        },
      },
      {
        dia: "ontem",
        f: function () {
          setRangeHeader([
            {
              startDate: moment(
                moment().subtract(1, "days").format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).toDate(),
              endDate: moment(
                moment().subtract(1, "days").format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).toDate(),
              key: "selection",
            },
          ]);
        },
      },
      {
        dia: "este mês",
        f: function () {
          setRangeHeader([
            {
              startDate: moment().startOf("month").toDate(),
              endDate: moment(
                moment().format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).toDate(),
              key: "selection",
            },
          ]);
        },
      },
      { dia: "personalizado", f: function () {} },
    ];

    const item = p.find((d) => d.dia === dia);
    if (item) {
      item.f();
    }
  }

  const [listaParceiros, setListaParceiros] = useState<Empresa[]>([]);

  async function getParceiros() {
    const token = sessionStorage.getItem("@bill-token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await api.get("/admin/api/merchant", config);
      if (response.data.merchants) {
        setListaParceiros(response.data.merchants);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  const [payments, setPayments] = useState<Payment[]>([]);

  const [total, setTotal] = useState(0);

  const [maxPage, setMaxPage] = useState(0);
  const [thisPage, setThisPage] = useState(1);

  const [visiblePage, setVisiblePage] = useState(1);

  function handleThisPage(newPage: number) {
    setThisPage(newPage);
    //setVisiblePage(newPage)
  }

  useEffect(() => {
    setVisiblePage(thisPage);
    updatePayments();
  }, [thisPage]);

  async function updatePayments() {
    setLoading(true);
    const token = sessionStorage.getItem("@bill-token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const paymentSearch = "";

    const startDate = moment(rangeHeader[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(rangeHeader[0].endDate).format("YYYY-MM-DD");

    try {
      const response = await api.get(
        `/admin/api/payment?s=${apelido}&start=${startDate}&end=${endDate}&limit=${LIMIT}&offset=${
          (thisPage - 1) * LIMIT
        }${
          situacao.value.length > 0 ? `&status[]=${situacao.value}` : ""
        }&merchant=${empresa.value}`,
        config
      );

      const { data } = response;

      if (data.payments) {
        setPayments(data.payments);
      }
      if (data.total !== undefined) {
        setTotal(data.total);
        const t = data.total;
        const floorMax = Math.floor(t / LIMIT);
        if (t % LIMIT !== 0) {
          setMaxPage(floorMax + 1);
        } else {
          setMaxPage(floorMax);
        }
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  const [loadingExport, setLoadingExport] = useState(false);

  async function exportPayments(format: string) {
    setLoadingExport(true);
    const token = sessionStorage.getItem("@bill-token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/pdf",
      },
    };

    const startDate = moment(rangeHeader[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(rangeHeader[0].endDate).format("YYYY-MM-DD");

    try {
      const response = await api.get(
        `/admin/api/payment/report?format=${format}&s=${apelido}&start=${startDate}&end=${endDate}&limit=${LIMIT}&offset=${0}${
          situacao.value.length > 0 ? `&status[]=${situacao.value}` : ""
        }&merchant=${empresa.value}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
        }
      );

      const { data } = response;

      if (data) {
        const startDate = moment(rangeHeader[0].startDate).format("DD-MM-YYYY");
        const endDate = moment(rangeHeader[0].endDate).format("DD-MM-YYYY");

        fileDownload(
          response.data,
          `pagamentos${startDate}_${endDate}.${format}`
        );
        setLoadingExport(false);
      }
    } catch (error: any) {
      console.log(error);
      setLoadingExport(false);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }
  async function exportReportSearch() {
    setLoadingExport(true);

    try {
      if (!adm || empresa.value) {
        const startDate = moment(rangeHeader[0].startDate).format("YYYY-MM-DD");
        const endDate = moment(rangeHeader[0].endDate).format("YYYY-MM-DD");
        const data = await exportReportSearchRequest(
          startDate,
          endDate,
          empresa?.value
        );
      } else {
        enqueueSnackbar("Selecione um parceiro", { variant: "info" });
        setEmpresaDropdown(true);
      }
    } catch (error: any) {
      if (error?.response && error?.response.status === 401) {
        history.push("/login");
      }
      snackbarErrorHandler(error, enqueueSnackbar, "Relatório não disponível");
    } finally {
      setLoadingExport(false);
    }
  }

  async function getPayments() {
    setLoading(true);
    const token = sessionStorage.getItem("@bill-token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const startDate = moment(rangeHeader[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(rangeHeader[0].endDate).format("YYYY-MM-DD");

    try {
      const response = await api.get(
        `/admin/api/payment?s=${apelido}&start=${startDate}&end=${endDate}&limit=${LIMIT}&offset=${0}${
          situacao.value.length > 0 ? `&status[]=${situacao.value}` : ""
        }&merchant=${empresa.value}`,
        config
      );

      const { data } = response;

      if (data.payments) {
        setPayments(data.payments);
      }
      if (data.total !== undefined) {
        setTotal(data.total);
        const t = data.total;
        const floorMax = Math.floor(t / LIMIT);
        if (t % LIMIT !== 0) {
          setMaxPage(floorMax + 1);
        } else {
          setMaxPage(floorMax);
        }
        setThisPage(1);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  useEffect(() => {
    getParceiros();
  }, []);

  const [relatorios, setRelatorios] = useState<Relatorio[]>([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    //getRelatorios();
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situacao, empresa, tipo, rangeHeader, apelido]);

  const exportRef = useRef<any>(null);
  const [exportDropdown, setExportDropdown] = useState<boolean>(false);

  const empresaRef = useRef<any>(null);
  const [empresaDropdown, setEmpresaDropdown] = useState(false);

  const situacaoRef = useRef<any>(null);
  const [situacaoDropdown, setSituacaoDropdown] = useState(false);

  const tipoRef = useRef<any>(null);
  const [tipoDropdown, setTipoDropdown] = useState(false);

  useOutsideAlert(exportRef, handleClickOutsideExport);
  useOutsideAlert(empresaRef, handleClickOutsideEmpresa);
  useOutsideAlert(situacaoRef, handleClickOutsideSituacao);
  useOutsideAlert(tipoRef, handleClickOutsideTipo);

  function handleClickOutsideEmpresa(e: any) {
    if (
      empresaRef.current &&
      empresaRef.current !== null &&
      !empresaRef.current.contains(e.target)
    ) {
      setEmpresaDropdown(false);
    }
  }
  function handleClickOutsideExport(e: any) {
    if (
      exportRef.current &&
      exportRef.current !== null &&
      !exportRef.current.contains(e.target)
    ) {
      setExportDropdown(false);
    }
  }

  function handleClickOutsideSituacao(e: any) {
    if (
      situacaoRef.current &&
      situacaoRef.current !== null &&
      !situacaoRef.current.contains(e.target)
    ) {
      setSituacaoDropdown(false);
    }
  }

  function handleClickOutsideTipo(e: any) {
    if (
      tipoRef.current &&
      tipoRef.current !== null &&
      !tipoRef.current.contains(e.target)
    ) {
      setTipoDropdown(false);
    }
  }

  const [adm, setAdm] = useState(false);

  useEffect(() => {
    const l = localStorage.getItem("@bill-login");
    if (l) {
      const login = JSON.parse(l);
      if (login && login.type === 999) {
        setAdm(true);
      } else {
        setAdm(false);
      }
    }
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderRow>
          <S.HeaderContainer>
            <S.HeaderTitle>Pagamentos</S.HeaderTitle>
            {headerData.map((data) => {
              return (
                <S.HeaderButton
                  key={data.dia}
                  onClick={(e) => {
                    e.preventDefault();
                    setFiltroDia(data.dia);
                    handleRange(data.dia);
                  }}
                  active={data.dia === filtroDia}
                >
                  <p>{data.dia}</p>
                </S.HeaderButton>
              );
            })}

            <CalendarioPersonalizadoV2
              item={filtroDia}
              setItem={setFiltroDia}
              range={rangeHeader}
              setRange={setRangeHeader}
            />

            <Dropdown show={exportDropdown} ref={exportRef}>
              <Dropdown.Toggle
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExportDropdown(!exportDropdown);
                }}
                className="dropdown-none"
              >
                <S.HeaderExport isActive={exportDropdown || loadingExport}>
                  <span className="ExportDropdown">
                    {!loadingExport ? (
                      "Exportar"
                    ) : (
                      <img alt="loading" src={Loading} />
                    )}
                  </span>
                  <img
                    className="ExportArrowDown_white"
                    alt=""
                    src={ArrowDownWhite}
                  />
                  <img
                    className="ExportArrowDown_dark"
                    alt=""
                    src={ArrowDownDark}
                  />
                </S.HeaderExport>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <S.ExportHeaderMenu>
                  <div className="export_header_menu_title">
                    Exportar relatório
                  </div>
                  <Dropdown.Item
                    onSelect={(e) => {
                      //e.preventDefault();
                      exportPayments("pdf");
                      setExportDropdown(false);
                    }}
                  >
                    <S.ExportReportButton>
                      <p>Relatório de Transações em PDF</p>
                      <UilDownloadAlt className="download_icon" />
                    </S.ExportReportButton>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={(e) => {
                      //e.preventDefault();
                      exportPayments("xls");
                      setExportDropdown(false);
                    }}
                  >
                    <S.ExportReportButton>
                      <p>Relatório de Transações em XLS</p>
                      <UilDownloadAlt className="download_icon" />
                    </S.ExportReportButton>
                  </Dropdown.Item>

                  <>
                    {/* <Dropdown.Item
                      onSelect={(e) => {
                        //e.preventDefault();
                        exportReportSearch();
                        setExportDropdown(false);
                      }}
                    >
                      <S.ExportReportButton>
                        <p>Relatório de Guias em PDF</p>
                        <UilDownloadAlt className="download_icon" />
                      </S.ExportReportButton>
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      onSelect={(e) => {
                        //e.preventDefault();
                        exportReportSearch();
                        setExportDropdown(false);
                      }}
                    >
                      <S.ExportReportButton>
                        <p>Relatório de Controle Diário em PDF</p>
                        <UilDownloadAlt className="download_icon" />
                      </S.ExportReportButton>
                    </Dropdown.Item>
                  </>
                </S.ExportHeaderMenu>
              </Dropdown.Menu>
            </Dropdown>

            {/*<HeaderUserContainer>


				</HeaderUserContainer>*/}
          </S.HeaderContainer>
          <HeaderUserButtonV2 />
        </S.HeaderRow>

        <S.RelatoriosSelectContainer>
          <DebouncerInput
            clearButton
            placeholder="Buscar"
            setSearchItem={setApelido}
          />

          <S.SearchSelector
            onClick={(e) => {
              e.preventDefault();
              setTipo({ value: "", label: "Todas as formas" });
              setSituacao({ value: "", label: "Todas as situações" });
              setEmpresa({ value: "", label: "Todas as empresas" });
              setApelido("");
              setSearchInput("");
            }}
            active={
              apelido === "" &&
              situacao.value === "" &&
              empresa.value === "" &&
              tipo.value === ""
            }
          >
            Tudo
          </S.SearchSelector>

          {adm && (
            <Dropdown ref={empresaRef} show={empresaDropdown}>
              <Dropdown.Toggle
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEmpresaDropdown(!empresaDropdown);
                }}
                style={{ padding: 0 }}
                className="dropdown-toggle-categoria"
              >
                <S.SearchSelector
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  pressed={empresaDropdown}
                  active={empresa.value !== ""}
                >
                  {empresa.value === "" ? "Parceiro" : empresa.label}
                  {empresa.value !== "" && (
                    <img
                      className="ExportArrowDown"
                      alt=""
                      src={ArrowDownWhite}
                    />
                  )}
                </S.SearchSelector>
              </Dropdown.Toggle>
              <DropdownMenu
                style={{
                  background: "transparent",
                  border: 0,
                  marginTop: -10,
                }}
              >
                <S.SelectMenu>
                  <Dropdown.Item
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEmpresa({ value: "", label: "Todos as empresas" });
                      setEmpresaDropdown(false);
                    }}
                  >
                    <S.SelectItem active={empresa.value === ""}>
                      {"Todos"}
                    </S.SelectItem>
                  </Dropdown.Item>
                  {listaParceiros.map((e) => {
                    return (
                      <Dropdown.Item
                        style={{ padding: 0 }}
                        //className='dropdown-item-categoria'
                        onClick={(a) => {
                          a.preventDefault();
                          a.stopPropagation();
                          setEmpresa({
                            value: e.id.toString(),
                            label: e.name,
                          });
                          setEmpresaDropdown(false);
                        }}
                      >
                        <S.SelectItem
                          active={empresa.value === e.id.toString()}
                        >
                          {e.name}
                        </S.SelectItem>
                      </Dropdown.Item>
                    );
                  })}
                </S.SelectMenu>
              </DropdownMenu>
            </Dropdown>
          )}

          <Dropdown ref={situacaoRef} show={situacaoDropdown}>
            <Dropdown.Toggle
              style={{ padding: 0 }}
              className="dropdown-toggle-categoria"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSituacaoDropdown(!situacaoDropdown);
              }}
            >
              <S.SearchSelector
                pressed={situacaoDropdown}
                onClick={(e) => {
                  e.preventDefault();
                }}
                active={situacao.value !== ""}
              >
                {situacao.value === "" ? "Situação" : situacao.label}
                {situacao.value !== "" && (
                  <img
                    className="ExportArrowDown"
                    alt=""
                    src={ArrowDownWhite}
                  />
                )}
              </S.SearchSelector>
            </Dropdown.Toggle>
            <DropdownMenu
              style={{
                background: "#f6f6f8",
                border: 0,
                //marginTop: -10,
                marginTop: 14,
                padding: 0,
                borderRadius: 18,
              }}
            >
              <S.SelectMenu
                className="scrollable-dropdown"
                style={{ marginTop: 0 }}
              >
                <Dropdown.Item
                  style={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSituacao({ value: "", label: "Todas as situações" });
                    setSituacaoDropdown(false);
                  }}
                >
                  <S.SelectItem active={situacao.value === ""}>
                    {"Todos"}
                  </S.SelectItem>
                </Dropdown.Item>
                {situacoes.map((e) => {
                  return (
                    <Dropdown.Item
                      style={{ padding: 0 }}
                      //className='dropdown-item-categoria'
                      onClick={(a) => {
                        a.preventDefault();
                        a.stopPropagation();
                        setSituacao({
                          value: e.value.toString(),
                          label: e.label,
                        });
                        setSituacaoDropdown(false);
                      }}
                    >
                      <S.SelectItem
                        active={situacao.value === e.value.toString()}
                      >
                        {e.label}
                      </S.SelectItem>
                    </Dropdown.Item>
                  );
                })}
              </S.SelectMenu>
            </DropdownMenu>
          </Dropdown>
        </S.RelatoriosSelectContainer>
      </S.Header>

      <S.Body>
        <div>
          <S.TableContainer>
            {!loading && payments.length === 0 ? (
              <NotFoundComponent />
            ) : (
              <S.Table>
                <S.THead>
                  {adm ? (
                    <tr>
                      <S.Th active={true}>Tipo</S.Th>
                      <S.Th>Empresa</S.Th>
                      <S.Th>Cliente</S.Th>
                      <S.Th>Identificador</S.Th>
                      <S.Th>Criado em</S.Th>
                      <S.Th>Origem</S.Th>
                      <S.Th>Situação</S.Th>
                      <S.Th style={{ textAlign: "right" }}>valor</S.Th>

                      {/* <S.Th></S.Th> */}
                    </tr>
                  ) : (
                    <tr>
                      <S.Th active={true}>Criado em</S.Th>
                      <S.Th>Identificador</S.Th>
                      <S.Th>Cliente</S.Th>
                      <S.Th>Origem</S.Th>
                      <S.Th>Situação</S.Th>
                      <S.Th>Valor</S.Th>

                      {/* <S.Th></S.Th> */}
                    </tr>
                  )}
                </S.THead>
                <S.TBody>
                  {loading ? (
                    adm ? (
                      <S.BodyTR>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                      </S.BodyTR>
                    ) : (
                      <S.BodyTR>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                        <td>
                          <S.Skeleton style={{ width: "100%", height: 19 }} />
                        </td>
                      </S.BodyTR>
                    )
                  ) : payments.length ? (
                    payments.map((payment, index) => (
                      <PaymentTR
                        payment={payment}
                        key={payment.id}
                        index={index}
                        selectedPayments={selectedPayments}
                        isAdm={adm}
                      />
                    ))
                  ) : null}
                </S.TBody>
              </S.Table>
            )}
          </S.TableContainer>
          {!loading && total > 0 && (
            <div style={{ padding: 10 }}>
              <S.TableFooter>
                <p className="table_footer_label">
                  Mostrando{" "}
                  <span className="table_footer_label_strong">
                    {(thisPage - 1) * LIMIT + 1} -{" "}
                    {LIMIT * thisPage >= total ? total : LIMIT * thisPage}
                  </span>{" "}
                  de {total} transações
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleThisPage(visiblePage);
                    }}
                    className="table_footer_border_right table_footer_padding_right-20"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p className="table_footer_label">Você está na página </p>
                    <input
                      className="table_footer_input"
                      type="tel"
                      value={visiblePage}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/\D/g, "");

                        if (value.length > 0) {
                          let intValue = parseInt(value);
                          if (intValue > maxPage) {
                            setVisiblePage(maxPage);
                          } else setVisiblePage(intValue);
                        } else {
                          setVisiblePage(0);
                        }
                      }}
                    />
                    <p className="table_footer_label">de {maxPage} </p>
                  </form>
                  <div className="table_footer_button_container table_footer_margin_left-20 table_footer_border_right-12 table_footer_border_left-12">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (visiblePage > 1) {
                          handleThisPage(visiblePage - 1);
                        }
                      }}
                      className="table_footer_button table_footer_border_left-12"
                    >
                      <img alt="<" src={ArrowLeftWhite} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (visiblePage !== maxPage) {
                          handleThisPage(visiblePage + 1);
                        }
                      }}
                      className="table_footer_button table_footer_border_right-12"
                    >
                      <img alt=">" src={ArrowRightWhite} />
                    </button>
                  </div>
                </div>
              </S.TableFooter>
            </div>
          )}
        </div>
      </S.Body>
    </S.Container>
  );
}

function RelatoriosTR({
  relatorio,
  handleSelectedInvoices,
  selectedInvoices,
}: {
  selectedInvoices: number[];
  relatorio: Relatorio;
  handleSelectedInvoices(i: number): void;
}) {
  function isSelected() {
    return selectedInvoices.includes(relatorio.id);
  }

  //console.log(relatorio);
  const [modalVisible, setModalVisible] = useState(false);

  function getStatus() {
    const find = transactionStatuses.find((f) => f.value === relatorio.status);
    if (find) {
      return find.label;
    }
    return "";
  }

  return (
    <S.BodyTR
      active={isSelected()}
      onClick={(e) => {
        e.preventDefault();
        handleSelectedInvoices(relatorio.id);
      }}
    >
      <Modal
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      >
        <Modal.Body>
          {/*	<ModalScreen
						setModalVisible={setModalVisible}
						relatorio={relatorio}
					/>*/}
        </Modal.Body>
      </Modal>
      <td>{"Fatura"}</td>
      <td>{relatorio.merchant?.name || ""}</td>
      <td>{relatorio.name || ""}</td>
      <td>{relatorio.ref}</td>
      <td>{relatorio.created}</td>
      <td>{relatorio.device.name}</td>
      <td>{getStatus()}</td>
      <td style={{ textAlign: "right" }}>R$ {relatorio.invoice}</td>

      <td>
        <S.Detalhes
          onClick={(e) => {
            setModalVisible(true);
            //dispatch(ToggleModal(modules, true));
          }}
        >
          Detalhes
        </S.Detalhes>
      </td>
    </S.BodyTR>
  );
}



export default connect((state: InitialStateProps) => ({
  modules: state.showModal,
}))(Relatorios);

function useOutsideAlert(ref: any, handleClickOutside: any) {
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
