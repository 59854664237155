import { useState } from "react";
import { Payment } from "../../../../models/model";
import { GlobalRow } from "../../../../utils/GlobalRow";
import { statusesData } from "../../../../utils/statusesList";
import * as S from './styles'
import moment from "moment";
import { StringToCurrencyNoSifra } from "../../../../utils/StringToCurrency";
import { Modal } from "react-bootstrap";
import { ModalRelatorioScreen } from "../../RelatorioModal";

export function PaymentTR({
  payment,
  selectedPayments,
  index,
  isAdm,
}: {
  selectedPayments: string[];
  payment: Payment;
  index: number;
  isAdm: boolean;
}) {
  function isSelected() {
    return selectedPayments.includes(payment.id);
  }

  //console.log(relatorio);
  const [modalVisible, setModalVisible] = useState(false);

  const ref = payment.transactions[0] ? payment.transactions[0].ref : "-";

  function getStatus() {
    const find = statusesData.find((f) => f.value === payment.status);
    if (find) {
      return (
        <GlobalRow justifyContent="flex-start" gap="8px">
          {find.iconColor ? (
            <S.StatusIcon statusColor={find.iconColor} />
          ) : null}{" "}
          {find.label}
        </GlobalRow>
      );
    }
    return payment.status;
  }

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }

  const identifierRow = () => {
    if (payment.invoices) {
      if (payment.invoices.length) {
        let identifierText = "-";
        const findInvoice = payment.invoices.find(
          (invoice) =>
            (payment?.merchant?.id === 6 && invoice.code) ||
            (payment?.merchant?.id !== 6 && invoice.contentRefDate)
        );
        if (findInvoice) {
          identifierText = `${
            payment?.merchant?.id === 6
              ? findInvoice.code
              : findInvoice.contentRefDate
          }`;
        }
        return (
          <GlobalRow gap="12px" justifyContent="flex-start">
            {identifierText}{" "}
            {payment.invoices.length > 1 ? (
              <S.InvoiceIdTag>
                {`+${payment.invoices.length - 1}`}
              </S.InvoiceIdTag>
            ) : null}{" "}
          </GlobalRow>
        );
      }
    }

    return <>-</>;
  };

  return (
    <>
      {isAdm ? (
        <S.BodyTR
          isEven={index % 2 === 0}
          active={isSelected()}
          onClick={(e) => {
            e.preventDefault();
            setModalVisible(true);

            //   handleSelectedInvoices(payment.id);
          }}
        >
          <td>{"Fatura"}</td>
          <td>{payment.merchant ? payment.merchant.name : "-"}</td>
          <td>
            {payment.transactions[0] ? payment.transactions[0].name : "-"}
          </td>
          <td>{identifierRow()}</td>
          <td>{StringToLocalDate(payment.created)}</td>
          <td>{payment.device?.name || "-"}</td>
          <td>{getStatus()}</td>
          <td style={{ textAlign: "right" }}>
            {StringToCurrencyNoSifra(payment.total)}
          </td>

          {/* <td>
            <S.Detalhes
              onClick={(e) => {
                setModalVisible(true);
                //dispatch(ToggleModal(modules, true));
              }}
            >
              Detalhes
            </S.Detalhes>
          </td> */}
        </S.BodyTR>
      ) : (
        <S.BodyTR
          isEven={index % 2 === 0}
          active={isSelected()}
          onClick={(e) => {
            e.preventDefault();
            setModalVisible(true);

            //   handleSelectedInvoices(payment.id);
          }}
        >
          <td>{StringToLocalDate(payment.created)}</td>
          <td>{identifierRow()}</td>
          <td>
            {payment.transactions[0] ? payment.transactions[0].name : "-"}
          </td>
          <td>{payment.device?.name || "-"}</td>
          <td>{getStatus()}</td>
          <td style={{ textAlign: "right" }}>
            {StringToCurrencyNoSifra(payment.total)}
          </td>

          {/* <td>{payment.merchant ? payment.merchant.name : "-"}</td>
            <td>
              {payment.transactions[0] ? payment.transactions[0].name : "-"}
            </td>
            <td>{ref || "-"}</td>
            <td>{StringToLocalDate(payment.created)}</td>
            <td>{payment.device?.name || "-"}</td>
            <td>{getStatus()}</td>
            <td style={{ textAlign: "right" }}>
              {StringToCurrencyNoSifra(payment.total)}
            </td> */}
        </S.BodyTR>
      )}

      <Modal
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      >
        <Modal.Body>
          {
            <ModalRelatorioScreen
              setModalVisible={setModalVisible}
              payment={payment}
            />
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
