/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
//import MenuContainer from '../../components/Menu';

import * as S from "./style";
import {
  UilCalendarAlt,
  UilSearch,
  UilFilterSlash,
  UilDownloadAlt,
} from "@iconscout/react-unicons";
import { connect } from "react-redux";
import { InitialStateProps } from "../../store";
import { Dropdown, Modal } from "react-bootstrap";
//import { Modal } from 'react-bootstrap';

import moment from "moment";
import { DateRange } from "react-date-range";
import { pt } from "date-fns/locale";
import { Empresa, Payment, Relatorio } from "../../models/model";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { colors } from "../../globalStyles/colors";

import ArrowDownWhite from "../../assets/images/arrow_dropdown-white.svg";
import ArrowDownDark from "../../assets/images/arrow_dropdown-dark.svg";
import ArrowRightWhite from "../../assets/images/arrow_right-white.svg";
import ArrowLeftWhite from "../../assets/images/arrow_left-white.svg";
import SearchGray from "../../assets/images/search-gray.svg";
import Loading from "../../assets/images/loading-roll.svg";
import HeaderUserButton from "../../components/logOut";
import fileDownload from "js-file-download";
import { DebouncerInput } from "../../components/InputV2";
import { CalendarioPersonalizado } from "../../components/CalendarioContainer";
import {
  paymentStatusesData,
  situacoesStatuses,
  statusesData,
  transactionStatuses,
} from "../../utils/statusesList";
import { HeaderUserButtonV2 } from "../../components/logOutV2";
import { StringToCurrencyNoSifra } from "../../utils/StringToCurrency";
import { GlobalRow } from "../../utils/GlobalRow";
import {
  exportReportSearchRequest,
  getCancellationRequestList,
  getPaymentInvoiceRequest,
  getPaymentRequestList,
  invoiceAjustmenTypes,
} from "./viewModel";
import { NotFoundComponent } from "../../components/NotFound";
import { EnqueueSnackbar, useSnackbar } from "notistack";
import { snackbarErrorHandler } from "../../utils/snackbarErrorHandler";
import { CalendarioPersonalizadoV2 } from "../../components/CalendarioContainerV2";
import { GlobalColumn } from "../../utils/GlobalColumn";
import { InvoiceAjustmentProps, InvoicePaymentInfo } from "./Models";
import { ConciliadorPaymentModal } from "./ConciliadorPaymentModal";
import { PaymentTR } from "./components/paymentTR";
import { removeStartSpaces } from "../../utils/removeStartSpaces";
import PaidIcon from "../../assets/images/paid.svg";
import SourceNotes from "../../assets/images/source_notes.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import ExpandCircleDown from "../../assets/images/expand_circle_down.svg";

import { ReopenPaymentModal } from "./ReopenPaymentModal";
import { CalendarioPersonalizadoV3 } from "../../components/CalendarioContainerV3";
import { DeletePaymentModal } from "./DeletePaymentModal";

interface Data {
  modules: any;
  dispatch: any;
}

const LIMIT = 25;

function ConciliadorPage({ modules, dispatch }: Data) {
  const { enqueueSnackbar } = useSnackbar();

  const [guia, setGuia] = useState("");

  const [isOpenConciliadorPaymentModal, setIsOpenConciliadorPaymentModal] =
    useState(false);
  const [isOpenReopenPaymentModal, setIsOpenReopenPaymentModal] =
    useState(false);
  const [isOpenDeletePaymentModal, setIsOpenDeletePaymentModal] =
    useState(false);

  const [user, setUser] = useState<any>();
  const [filtroDia, setFiltroDia] = useState("hoje");
  const [rangeHeader, setRangeHeader] = useState([
    {
      startDate: moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").toDate(),
      endDate: moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").toDate(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const login = localStorage.getItem("@bill-login");
    if (login) {
      const parseLogin = JSON.parse(login);

      setUser(parseLogin);
    }
  }, []);

  //console.log(range)

  const [listaParceiros, setListaParceiros] = useState<Empresa[]>([]);

  const [payments, setPayments] = useState<InvoiceAjustmentProps[]>([]);
  const [total, setTotal] = useState(0);

  const [maxPage, setMaxPage] = useState(0);
  const [thisPage, setThisPage] = useState(1);

  const [visiblePage, setVisiblePage] = useState(1);

  const typeRef = useRef<any>(null);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [typeFilter, setTypeFilter] = useState({
    value: -1,
    label: "Tipo de ajuste",
  });

  function refreshPage() {
    setGuia("");
    updatePayments();
  }

  function handleThisPage(newPage: number) {
    setThisPage(newPage);
    //setVisiblePage(newPage)
  }

  function clearPagination() {
    setThisPage(1);
  }

  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const history = useHistory();

  const [adm, setAdm] = useState(false);

  async function getCancellationRequestListFn() {
    try {
      getCancellationRequestList();
    } catch (error) {
      snackbarErrorHandler(error, enqueueSnackbar, "Falha na operação");
    }
  }

  function debounceChangeGuia(search: string) {
    setGuia(search);
    // setThisPage(1)
    updatePayments(search);
    setVisiblePage(1)
  }

  useEffect(() => {
    setVisiblePage(thisPage);
    updatePayments();
  }, [thisPage, typeFilter]);

  function paymentListBuildParams(searchItem?: string) {
    const params = new URLSearchParams();

    if (!searchItem) {
      params.append("offset", `${(thisPage - 1) * LIMIT}`);
    } else {
      params.append("offset", "0");
    }
    params.append("limit", `${LIMIT}`);
    if (searchItem) {
      params.append("invoice", searchItem);
    } else if (guia) {
      params.append("invoice", guia);
    }
    if (typeFilter && typeFilter.value !== -1) {
      params.append("type", `${typeFilter.value}`);
    }

    return `?${params.toString()}`;
  }

  async function updatePayments(searchItem?: string) {
    setLoadingList(true);

    try {
      // typeFilter
      const data = await getPaymentRequestList(
        paymentListBuildParams(searchItem)
      );

      if (data.invoice_adjustments) {
        setPayments(data.invoice_adjustments);
      }
      if (data.count !== undefined) {
        setTotal(data.count);
        const t = data.count;
        const floorMax = Math.floor(t / LIMIT);
        if (t % LIMIT !== 0) {
          setMaxPage(floorMax + 1);
        } else {
          setMaxPage(floorMax);
        }
      }
      setLoadingList(false);
    } catch (error: any) {
      console.log(error);
      setLoadingList(false);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  useEffect(() => {
    const l = localStorage.getItem("@bill-login");
    if (l) {
      const login = JSON.parse(l);
      if (login && login.type === 999) {
        setAdm(true);
      } else {
        setAdm(false);
      }
    }
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderRow>
          <GlobalRow alignItems="center" justifyContent="flex-start" gap="32px">
            <S.HeaderTitle>Ajustes de Guias</S.HeaderTitle>
            <GlobalRow gap="8px">
              <S.HeaderItemButton
                onClick={() => {
                  setIsOpenConciliadorPaymentModal(true);
                }}
              >
                <img alt="$" src={PaidIcon} />
                <p>Baixa manual</p>
              </S.HeaderItemButton>

              <S.HeaderItemButton
                onClick={() => {
                  setIsOpenReopenPaymentModal(true);
                }}
              >
                <img alt="$" src={SourceNotes} />
                <p>Reativar</p>
              </S.HeaderItemButton>
              <S.HeaderItemButton
                onClick={() => {
                  setIsOpenDeletePaymentModal(true);
                }}
              >
                <img alt="$" src={DeleteIcon} />
                <p>Excluir</p>
              </S.HeaderItemButton>
            </GlobalRow>
            {/* <DebouncerInput
            placeholder="Buscar por Guia..."
            setSearchItem={(s: string) => {
              setGuia(removeStartSpaces(s));
            }}
          />

          <S.Detalhes
            onClick={(e) => {
              getPaymentInvoice(guia);
            }}
          >
            {loading ? (
              <img className="loading-roll" alt="loading" src={Loading} />
            ) : (
              "Conciliar"
            )}
          </S.Detalhes> */}
          </GlobalRow>

          <HeaderUserButtonV2 />
        </S.HeaderRow>
        <S.HeaderRow className="container_border_top">
          <GlobalRow alignItems="center" justifyContent="flex-start" gap="32px">
            <S.HeaderSubTitle>Histórico de ajustes</S.HeaderSubTitle>
            <GlobalRow gap="8px">
              <DebouncerInput
                clearButton
                placeholder="Número da guia"
                setSearchItem={debounceChangeGuia}
                customClassName={"debouncer_input"}
              />

              {/* <CalendarioPersonalizadoV3
                item={filtroDia}
                setItem={setFiltroDia}
                range={rangeHeader}
                setRange={setRangeHeader}
              /> */}
              <Dropdown ref={typeRef} show={typeDropdown}>
                <Dropdown.Toggle
                  style={{ padding: 0 }}
                  className="dropdown-toggle-categoria"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTypeDropdown(!typeDropdown);
                  }}
                >
                  <S.SearchSelector
                    pressed={typeDropdown}
                    onClick={(e) => {
                      e.preventDefault();
                      // typeFilter, setTypeFilter;
                    }}
                    active={typeFilter.value !== -1}
                  >
                    {typeFilter.label}
                    {typeFilter.value !== -1 && (
                      <img
                        className="ExportArrowDown"
                        alt=""
                        src={ArrowDownWhite}
                      />
                    )}
                  </S.SearchSelector>
                </Dropdown.Toggle>
                <DropdownMenu
                  style={{
                    background: "#f6f6f8",
                    border: 0,
                    //marginTop: -10,
                    marginTop: 14,
                    padding: 0,
                    borderRadius: 18,
                  }}
                >
                  <S.SelectMenu
                    className="scrollable-dropdown"
                    style={{ marginTop: 0 }}
                  >
                    <Dropdown.Item
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTypeFilter({ value: -1, label: "Tipo de ajuste" });
                        setTypeDropdown(false);
                        clearPagination();
                      }}
                    >
                      <S.SelectItem active={typeFilter.value === -1}>
                        {"Todos"}
                      </S.SelectItem>
                    </Dropdown.Item>
                    {invoiceAjustmenTypes.map((e) => {
                      return (
                        <Dropdown.Item
                          style={{ padding: 0 }}
                          //className='dropdown-item-categoria'
                          onClick={(a) => {
                            a.preventDefault();
                            a.stopPropagation();
                            setTypeFilter({
                              value: e.value,
                              label: e.label,
                            });
                            setTypeDropdown(false);
                            clearPagination();
                          }}
                        >
                          <S.SelectItem active={typeFilter.value === e.value}>
                            {e.label}
                          </S.SelectItem>
                        </Dropdown.Item>
                      );
                    })}
                  </S.SelectMenu>
                </DropdownMenu>
              </Dropdown>
            </GlobalRow>
          </GlobalRow>

          {/* <HeaderUserButtonV2 /> */}
        </S.HeaderRow>
      </S.Header>

      {isOpenConciliadorPaymentModal ? (
        <Modal
          show={true}
          onHide={() => {
            setIsOpenConciliadorPaymentModal(false);
            // setModalVisible(false);
          }}
        >
          <Modal.Body>
            <ConciliadorPaymentModal
              setModalVisible={(b: boolean) => {
                if (!b) setIsOpenConciliadorPaymentModal(false);
              }}
              // payment={foundInvoice}
              refreshPage={refreshPage}
            />
          </Modal.Body>
        </Modal>
      ) : null}

      {isOpenReopenPaymentModal ? (
        <Modal
          show={true}
          onHide={() => {
            setIsOpenReopenPaymentModal(false);
          }}
        >
          <Modal.Body>
            <ReopenPaymentModal
              setModalVisible={(b: boolean) => {
                if (!b) setIsOpenReopenPaymentModal(false);
              }}
              // payment={foundInvoice}
              refreshPage={refreshPage}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {isOpenDeletePaymentModal ? (
        <Modal
          show={true}
          onHide={() => {
            setIsOpenDeletePaymentModal(false);
          }}
        >
          <Modal.Body>
            <DeletePaymentModal
              setModalVisible={(b: boolean) => {
                if (!b) setIsOpenDeletePaymentModal(false);
              }}
              // payment={foundInvoice}
              refreshPage={refreshPage}
            />
          </Modal.Body>
        </Modal>
      ) : null}

      <S.Body>
        <div>
          <S.TableContainer>
            {!loadingList && !payments?.length ? (
              <NotFoundComponent />
            ) : (
              <S.Table>
                {loadingList ? (
                  <S.BodyTR>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                  </S.BodyTR>
                ) : (
                  <>
                    <S.THead>
                      {payments?.length ? (
                        <tr>
                          <S.Th active={true}>GUIA</S.Th>
                          <S.Th>TIPO DE AJUSTE</S.Th>
                          <S.Th>INTERESSADO</S.Th>
                          <S.Th>COMARCA</S.Th>
                          <S.Th>VALOR</S.Th>
                          <S.Th>CRIADO EM</S.Th>
                          <S.Th>VENCIMENTO</S.Th>
                          <S.Th>SITUAÇÃO</S.Th>
                          <S.Th>DATA DA AÇÃO</S.Th>
                          <S.Th></S.Th>

                          {/* <S.Th></S.Th> */}
                        </tr>
                      ) : null}
                    </S.THead>
                    <S.TBody>
                      {payments?.length
                        ? payments.map((pay) => (
                            <PaymentTR
                              // refreshPage={refreshPage}
                              payment={pay}
                              index={0}
                              isAdm={false}
                              selectedPayments={[]}
                              // enqueueSnackbar={enqueueSnackbar}
                            />
                          ))
                        : null}
                    </S.TBody>
                  </>
                )}
              </S.Table>
            )}
          </S.TableContainer>
          {!loadingList && total > 0 && (
            <div style={{ padding: 10 }}>
              <S.TableFooter>
                <p className="table_footer_label">
                  Mostrando{" "}
                  <span className="table_footer_label_strong">
                    {(visiblePage - 1) * LIMIT + 1} -{" "}
                    {LIMIT * visiblePage >= total ? total : LIMIT * visiblePage}
                  </span>{" "}
                  de {total} conciliações
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleThisPage(visiblePage);
                    }}
                    className="table_footer_border_right table_footer_padding_right-20"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p className="table_footer_label">Você está na página </p>
                    <input
                      className="table_footer_input"
                      type="tel"
                      value={visiblePage}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/\D/g, "");

                        if (value.length > 0) {
                          let intValue = parseInt(value);
                          if (intValue > maxPage) {
                            setVisiblePage(maxPage);
                          } else setVisiblePage(intValue);
                        } else {
                          setVisiblePage(0);
                        }
                      }}
                    />
                    <p className="table_footer_label">de {maxPage} </p>
                  </form>
                  <div className="table_footer_button_container table_footer_margin_left-20 table_footer_border_right-12 table_footer_border_left-12">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (visiblePage > 1) {
                          handleThisPage(visiblePage - 1);
                        }
                      }}
                      className="table_footer_button table_footer_border_left-12"
                    >
                      <img alt="<" src={ArrowLeftWhite} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (visiblePage !== maxPage) {
                          handleThisPage(visiblePage + 1);
                        }
                      }}
                      className="table_footer_button table_footer_border_right-12"
                    >
                      <img alt=">" src={ArrowRightWhite} />
                    </button>
                  </div>
                </div>
              </S.TableFooter>
            </div>
          )}
        </div>
      </S.Body>
    </S.Container>
  );
}

export default connect((state: InitialStateProps) => ({
  modules: state.showModal,
}))(ConciliadorPage);
