import moment from "moment";
import React, { useState } from "react";
import * as yup from "yup";
import SaveIcon from "../../../assets/images/save-icon.svg";
import LoadingGreen from "../../../assets/images/loading-roll-green.svg";
import Loading from "../../../assets/images/loading-roll.svg";
import { paymentMethodListValueString } from "../../../utils/paymentMethodList";
import * as S from "./styles";
import { GlobalRow } from "../../../utils/GlobalRow";
import { GlobalColumn } from "../../../utils/GlobalColumn";
import { InvoicePaymentInfo } from "../Models";
import {
  InputContainerV3,
  SelectContainerV3,
} from "../../../components/InputV3";
import {
  formatFloatToCurrency,
  formatStringToFloat,
} from "../../../utils/StringToCurrency";
import { snackbarErrorHandler } from "../../../utils/snackbarErrorHandler";
import { useSnackbar } from "notistack";
import {
  getPaymentInvoiceCancelationRequest,
  getPaymentInvoiceRequest,
  getSingleInvoiceAjustment,
  patchProcressSingleInvoiceAjustment,
  postCreateCancellationRequest,
  postCreateSingleInvoiceAjustment,
  postPaymentCancellationRequest,
} from "../viewModel";
import { PaymentRequestDetail } from "./Models";
import {
  paymentStatusesData,
  transactionStatuses,
} from "../../../utils/statusesList";
import NotFoundInvoices from "../../../assets/images/not_found_invoices.svg";
import { removeStartSpaces } from "../../../utils/removeStartSpaces";
import { DebouncerInput } from "../../../components/InputV4";

interface ModalProps {
  setModalVisible(b: boolean): void;
  refreshPage: () => void;
}

export function ReopenPaymentModal({
  setModalVisible,
  refreshPage,
}: ModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [paymentRequestDetail, setPaymentRequestDetail] = useState<
    PaymentRequestDetail | undefined
  >();
  const [payment, setPayment] = useState<InvoicePaymentInfo | undefined>();

  const [paymentDate, setPaymentDate] = useState("");
  const [paymentHour, setPaymentHour] = useState("");
  const [guia, setGuia] = useState("");

  const [isNotFound, setIsNotFound] = useState(false);

  const [paymentReason, setPaymentReason] = useState("");

  async function createPostObj() {
    try {
      const schema = yup.object().shape({
        reason: yup.string().required(),
        ref_date: yup.string().required().min(5),
      });
      const postObj = {
        reason: paymentReason,
        ref_date: `${paymentDate} ${paymentHour}:00`,
      };

      await schema.validate(postObj, {
        abortEarly: false,
      });
      return { ...postObj, type: 2 };
    } catch (error) {
      const validationError = {} as any;
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          if (err.path) validationError[err.path] = true;
        });
      }
      setIsError(validationError);
      return undefined;
    }
  }

  async function postCreateRequest() {
    if (payment) {
      try {
        const data = await postCreateSingleInvoiceAjustment(payment.id, 2);
        setPaymentRequestDetail(data);
      } catch (error) {
        setModalVisible(false);
        snackbarErrorHandler(error, enqueueSnackbar, "Fatura não encontrada");
      }
    }
  }

  async function paymentRequestProcess() {
    if (paymentRequestDetail)
      try {
        if (loading) return;
        setLoading(true);
        const postObj = await createPostObj();
        if (postObj) {
          await patchProcressSingleInvoiceAjustment(
            paymentRequestDetail?.id,
            postObj
          );
          enqueueSnackbar("Guia reaberta com sucesso", {
            variant: "success",
          });
          setModalVisible(false);
          refreshPage();
        } else {
          throw new Error("");
        }
      } catch (error) {
        snackbarErrorHandler(error, enqueueSnackbar, "Falha em reabrir a guia");
      } finally {
        setLoading(false);
      }
  }

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY");
  }
  function StringToLocalDateHour(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }

  function StringToCurrency(value: string) {
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }
  function getStatus() {
    if (payment) {
      const find = paymentStatusesData.find((f) => f.value === payment.status);
      if (find) {
        return (
          <GlobalRow justifyContent="flex-start" gap="8px">
            {find.iconColor ? (
              <S.StatusIcon statusColor={find.iconColor} />
            ) : null}{" "}
            {find.label}
          </GlobalRow>
        );
      }
      return payment.status;
    }
    return "-";
  }

  React.useEffect(() => {
    if (payment) postCreateRequest();
  }, [payment]);

  async function getPaymentInvoice(textSearch: string) {
    if (textSearch) {
      setLoading(true);
      try {
        const data = await getSingleInvoiceAjustment(guia, 2);
        if (data.invoice && data.invoice.status === 1) {
          setPayment(data.invoice);
          return;
        }
        setPayment(undefined);
        setIsNotFound(true);
      } catch (error) {
        snackbarErrorHandler(
          error,
          enqueueSnackbar,
          "Falha ao encontrar invoice"
        );
        setPayment(undefined);
        setIsNotFound(true);
      } finally {
        setLoading(false);
      }
    } else {
      setPayment(undefined);
      setIsNotFound(true);
    }
  }

 

  return (
    <S.Modal style={{ maxWidth: 768 }}>
      <S.ModalHeader>
        <S.HeaderTitle>{"Reativar Guia"}</S.HeaderTitle>

        {payment ? (
          <GlobalRow gap="8px">
            <S.HeaderButton
              onClick={(e) => {
                e.preventDefault();
                setPayment(undefined);
              }}
            >
              Cancelar
            </S.HeaderButton>
            <S.HeaderButtonSave
              onClick={(e) => {
                e.preventDefault();
                paymentRequestProcess();
              }}
            >
              {loading ? (
                <>
                  <img className="loading-roll" alt="loading" src={Loading} />
                </>
              ) : (
                <>
                  <img src={SaveIcon} alt="save" />
                  Salvar
                </>
              )}
            </S.HeaderButtonSave>
          </GlobalRow>
        ) : (
          <GlobalRow gap="8px">
            <S.HeaderButton
              onClick={(e) => {
                e.preventDefault();
                setModalVisible(false);
              }}
            >
              Fechar
            </S.HeaderButton>
          </GlobalRow>
        )}
      </S.ModalHeader>

      {payment ? (
        <S.ModalContainer>
          <S.MenuItem backgroundColor="#E0E0E0">
            <GlobalRow
              alignItems="center"
              justifyContent="space-between"
              className="menu_margin_b-8 menu_item_header"
            >
              <p className="menu_item_title">Detalhes da cobrança</p>
            </GlobalRow>
            <GlobalColumn
              gap="16px"
              alignItems="flex-start"
              className="menu_padding_b-16 item_width-100"
            >
              <S.GridContainer
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                className="item_width-100"
              >
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Tipo</p>
                  <p className="menu_item_info menu_item_ellipsis">Guia </p>
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Valor do documento</p>
                  <p className="menu_item_info">
                    {StringToCurrency(`${payment.total}`) || "-"}
                  </p>
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Criado em</p>
                  <p className="menu_item_info menu_item_ellipsis">
                    {StringToLocalDateHour(payment.content?.billingPeriod)}
                  </p>
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Vencimento</p>
                  <p className="menu_item_info menu_item_ellipsis">
                    {StringToLocalDate(payment.paymentDueDate)}
                  </p>
                </GlobalColumn>
              </S.GridContainer>
              <S.GridContainer className="item_width-100">
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Processo</p>
                  <p className="menu_item_info">{payment.contentRef || "-"}</p>
                </GlobalColumn>
              </S.GridContainer>
              <S.GridContainer className="item_width-100">
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Guia</p>
                  <p className="menu_item_info">{payment.code || "-"}</p>
                  {/* <p className="menu_item_info">
                  {payment.content?.description || "-"}
                </p> */}
                </GlobalColumn>
              </S.GridContainer>
            </GlobalColumn>
          </S.MenuItem>

          <S.MenuItem backgroundColor="#E0E0E0">
            <GlobalRow
              alignItems="center"
              justifyContent="space-between"
              className="menu_margin_b-8 menu_item_header"
            >
              <p className="menu_item_title">Detalhes do pagamento</p>
              <div className="menu_detalhes_status">{getStatus()}</div>
            </GlobalRow>
            <GlobalColumn
              gap="16px"
              alignItems="flex-start"
              className="menu_padding_b-16 item_width-100"
            >
              <S.GridContainer
                gridTemplateColumns="1fr 1fr"
                className="item_width-100"
              >
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Total pago</p>
                  <p className="menu_item_info menu_item_ellipsis">
                    {StringToCurrency(`${payment.content.totalPaymentDue}`) ||
                      "-"}
                  </p>
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Pago em</p>
                  <p className="menu_item_info">
                    {StringToLocalDate(payment.paymentDueDate)}
                  </p>
                </GlobalColumn>
              </S.GridContainer>
            </GlobalColumn>
          </S.MenuItem>
          <S.MenuItem>
            <GlobalRow
              alignItems="center"
              justifyContent="space-between"
              className="menu_margin_b-8 menu_item_header"
            >
              <p className="menu_item_title">Dados do cancelamento</p>
            </GlobalRow>
            <GlobalColumn
              gap="16px"
              alignItems="flex-start"
              className="menu_padding_b-16 item_width-100"
            >
              <S.GridContainer className="item_width-100">
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <InputContainerV3
                    // isError={isError.login}
                    isError={isError.payment_date}
                    fieldLabel={"DATA DO CANCELAMENTO *"}
                    inputName={paymentDate}
                    setInputField={setPaymentDate}
                    placeholder={"DD/MM/AAAA"}
                    type="date"
                  />
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <InputContainerV3
                    // isError={isError.login}
                    isError={isError.payment_date}
                    fieldLabel={"HORA DO CANCELAMENTO *"}
                    inputName={paymentHour}
                    setInputField={setPaymentHour}
                    placeholder={"HH:MM"}
                    type="time"
                  />
                </GlobalColumn>
              </S.GridContainer>

              <S.GridContainer
                gridTemplateColumns="1fr"
                className="item_width-100"
              >
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <InputContainerV3
                    // isError={isError.login}
                    isError={isError.reason}
                    fieldLabel={"MOTIVO *"}
                    inputName={paymentReason}
                    setInputField={setPaymentReason}
                    placeholder={"Descreva o motivo..."}
                  />
                </GlobalColumn>
              </S.GridContainer>
            </GlobalColumn>
          </S.MenuItem>
        </S.ModalContainer>
      ) : (
        <S.SearchContainer>
          <DebouncerInput
            placeholder="Número da Guia..."
            setSearchItem={(s: string) => {
              setGuia(removeStartSpaces(s));
              setIsNotFound(false);
            }}
            customClassName="search_item_container"
            isButtonActive={guia.length > 0}
            searchFunction={() => {
              getPaymentInvoice(guia);
            }}
            isLoading={loading}
          />
          {loading ? (
            <>
              <img className="big-loading-roll" alt="loading" src={LoadingGreen} />
            </>
          ) : isNotFound ? (
            <GlobalColumn gap="24px">
              <img
                alt="not found"
                src={NotFoundInvoices}
                className="not_found_img"
              />
              <GlobalColumn>
                <p className="not_found_text">
                  Não encontramos resultados para sua busca
                </p>
                <p className="not_found_text">
                  Verifique o que foi digitado e tente novamente.
                </p>
              </GlobalColumn>
            </GlobalColumn>
          ) : null}
        </S.SearchContainer>
      )}
    </S.Modal>
  );
}
